import { Directive, HostListener } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Directive({
  selector: '[not-implemented]'
})
export class NonImplementedDirective {

  constructor(
    private toastController: ToastController
  ) { }

  @HostListener('click', ['$event']) onClick() {
    console.log('click')
    this.presentToast()
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: "Denne funktion er endnu ikke implementeret",
      duration: 2000,
    });
    toast.present();
  }

}
