import { Injectable, Injector } from '@angular/core';
import { Account } from '../data/account';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CredentialAuth } from '../data/credential-auth';
import { HttpPostService } from './http-post.service';
import { ApiService } from './api.service';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private account = new BehaviorSubject<Account>({
    token: null,
    user: null,
    rights: null,
    active_context: null,
    context_list: null,
    icalLink: null
  })

  constructor(
    private router: Router,
    private http: HttpPostService,
    private api: ApiService,
    private alertController: AlertController
  ) {
    this.loadAccount();
  }

  private storeAccount(remember: boolean): void {
    if (remember) {
      localStorage.setItem('cas-booking-admin-app.account', JSON.stringify(this.account.getValue()))
    }
    else {
      localStorage.removeItem('cas-booking-admin-app.account')
    }
  }

  private loadAccount(): void {
    const account = localStorage.getItem('cas-booking-admin-app.account');
    if (account) {
      this.account.next(JSON.parse(account));
      this.http.token = this.getToken();
      this.doLoginByToken(this.getToken());
    }
  }

  getAccount(): BehaviorSubject<Account> {
    return this.account;
  }

  isLoggedIn(): boolean {
    return this.account.getValue().token != undefined
  }

  getToken(): string {
    return this.account.getValue().token;
  }

  async alertNoLogin() {
    const alert = await this.alertController.create({
      header: 'Log ind',
      message: 'Kunne ikke logge ind med de angivne informationer',
      buttons: ['OK']
    });

    await alert.present();
  }

  doLogin(data: CredentialAuth, remember: boolean) {
    this.api.login(data).subscribe((result: Account) => {
      this.http.token = result.token;
      this.account.next(result)
      this.storeAccount(remember);
    }, error => {
      this.alertNoLogin()
    })
  }

  doLoginByToken(token: string) {
    this.api.checkToken(token).subscribe((result: Account) => {
      this.http.token = result.token;
      this.account.next(result)
      this.storeAccount(true);
    }, error => {
      this.doLogOut()
    })
  }

  doLogOut() {
    localStorage.removeItem('cas-booking-admin-app.account');
    this.account.next({
      token: null,
      user: null,
      rights: null,
      active_context: null,
      context_list: null,
      icalLink: null
    })
  }
}
