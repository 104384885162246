import { Injectable, Injector } from '@angular/core';
import { ConfigService } from './config.service';
import { CredentialAuth } from '../data/credential-auth';
import { CalendarEntriesRequest } from '../requests/calendar-entries-request';
import { HttpPostService } from './http-post.service';
import { CalendarEntry } from '../data/calendar-entry';
import { EditEntryRequest } from '../requests/edit-entry-request';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpPostService, private config: ConfigService, private injector: Injector) { }

  public login(data: CredentialAuth) {
    return this.http.post('account/login', data, "Logger ind")
  }

  public checkToken(token: string) {
    return this.http.post('account/check-token', {
      token
    })
  }

  public loadCalendarEntries(calendarId: string, data: CalendarEntriesRequest) {
    return this.http.post('calendar/' + calendarId + '/entries', data, 'Henter vagter')
  }

  public loadEntryNotes(calendarId: string, entryIds: number[]) {
    return this.http.post('notes/list-shifts', {
      calendarId,
      entries: entryIds
    })
  }

  public loadGroupsAndCalendars() {
    return this.http.post('groups/list');
  }

  public loadCalendarUsers(calendarId: string) {
    return this.http.post('calendar/' + calendarId + '/users', {
      users_context: {
        rights: [
          'temp.book'
        ]
      }
    })
  }

  public testCalendarUsers(calendarId: string, entryRequest: CalendarEntriesRequest) {
    return this.http.post('calendar/' + calendarId + '/entries/test', entryRequest);
  }

  public dashboard_unassignedShifts() {
    return this.http.post('account/dashboard/entries/unassigned')
  }
  public dashboard_unseenNotes() {
    return this.http.post('account/dashboard/unseen-notes')
  }

  editEntry(entry: CalendarEntry) {
    const edit_type = (entry.entry.user !== undefined && entry.entry.user) ? 'book' : entry.entry.availability_type;
    console.log(edit_type, entry.entry.user)

    const request = <EditEntryRequest>{
      id: entry.id,
      calendar_id: entry.calendar.id,
      title: entry.title,
      description: entry.description,
      start_at: entry.start_at,
      end_at: entry.end_at,
      force: true,
      resendNotification: false,
      shift_note: "",

      bookType: {
        name: edit_type
      },

      users: edit_type == 'book' ? [{ id: entry.entry.user.id }] : entry.entry.available_users.map(user => user.id)

    }
    console.log('editEntry', request)

    return this.http.post('calendar/' + request.calendar_id + '/entries/edit', request, "Gemmer vagt")
  }

  addEntry(entry: CalendarEntry) {
    const edit_type = (entry.entry.user !== undefined && entry.entry.user) ? 'book' : entry.entry.availability_type;
    console.log(edit_type, entry.entry.user)

    const request = <EditEntryRequest>{
      id: entry.id,
      calendar_id: entry.calendar.id,
      title: entry.title,
      description: entry.description,
      start_at: entry.start_at,
      end_at: entry.end_at,
      force: true,
      resendNotification: false,
      shift_note: "",

      bookType: {
        name: edit_type
      },

      users: edit_type == 'book' ? [{ id: entry.entry.user.id }] : entry.entry.available_users.map(user => user.id)

    }
    console.log('addEntry', request)

    return this.http.post('calendar/' + request.calendar_id + '/entries/add', request, "Opretter vagt")
  }

  notifyAddEntry(entry: CalendarEntry, notification_id) {
    this.http.post("calendar/" + entry.calendar.id + "/entries/notify", { notification_id });
  }

  public loadStandardShifts() {
    return this.http.post('standardtitles/list')
  }

}
