import { Injectable } from '@angular/core';
import { CalendarEntry } from '../data/calendar-entry';
import { User } from '../data/user';
import * as moment from 'moment';
import { ConfigService } from '../services/config.service';
import { deepDiffMapper } from '../classes/deep-diff-mapper';

@Injectable({
  providedIn: 'root'
})
export class EditEntryService {

  public _srcEntry: CalendarEntry
  public entry: CalendarEntry

  constructor(
    private config: ConfigService
  ) { }

  reset() {
    this.entry =
      (JSON.parse(JSON.stringify(this._srcEntry)));
  }

  setSourceEntry(val) {
    this._srcEntry = val
    this.reset();

    return this._srcEntry;
  }

  get hasChanges(): boolean {
    return this.getChanges('int.hasChanges').length > 0;
  }

  getChanges(src = ''): any[] {
    const diff = (new deepDiffMapper()).mapDiffOnlyFlat(this.entry, this._srcEntry);
    console.log('calc.changes', src, diff)

    let result = []
    if (diff['entry.user'] !== undefined) {
      if (this.entry.entry.user) {
        if (this._srcEntry.entry.user && this.entry.bookAction == 'book') {
          result.push({
            name: 'Tildeles',
            to: [this.entry.entry.user.profile.firstname, this.entry.entry.user.profile.lastname].join(' '),
            from: [this._srcEntry.entry.user.profile.firstname, this._srcEntry.entry.user.profile.lastname].join(' ')
          })
        }
        else {
          result.push({
            name: 'Tildeles',
            to: [this.entry.entry.user.profile.firstname, this.entry.entry.user.profile.lastname].join(' '),
            from: ''
          })
        }
      }
      else {
        result.push({
          name: 'Genudbydes',
          to: '',
          from: [this._srcEntry.entry.user.profile.firstname, this._srcEntry.entry.user.profile.lastname].join(' ')
        })
      }
    }

    if (diff['entry.available_users'] && this.entry.bookAction == 'offer') {
      console.log('available', this.entry, this._srcEntry)
      const usercount = this.entry.entry.available_users.length;
      result.push({
        name: 'Udbydes til',
        to: [usercount, usercount === 1 ? 'vikar' : 'vikarer'].join(' '),
        from: ''
      })
    }

    if (diff['start_at'] !== undefined) {
      const dst = moment(this.entry.start_at).locale('da');
      const src = moment(this._srcEntry.start_at).locale('da');

      if (!dst.isSame(src, 'day')) {
        result.push({
          name: 'Dato',
          to: dst.format(this.config.dateFormats.calendarFormat),
          from: src.format(this.config.dateFormats.calendarFormat)
        })
      }

      if (dst.format(this.config.dateFormats.timeFormat) != src.format(this.config.dateFormats.timeFormat)) {
        result.push({
          name: 'Fra kl.',
          to: dst.format(this.config.dateFormats.timeFormat),
          from: src.format(this.config.dateFormats.timeFormat)
        })
      }
    }

    if (diff['end_at'] !== undefined) {
      const dst = moment(this.entry.end_at).locale('da');
      const src = moment(this._srcEntry.end_at).locale('da');

      if (dst.format(this.config.dateFormats.timeFormat) != src.format(this.config.dateFormats.timeFormat)) {
        result.push({
          name: 'Til kl.',
          to: dst.format(this.config.dateFormats.timeFormat),
          from: src.format(this.config.dateFormats.timeFormat)
        })
      }
    }

    if (diff['title'] !== undefined) {
      result.push({
        name: 'Titel',
        to: this.entry.title,
        from: this._srcEntry.title
      })
    }

    if (diff['description'] !== undefined) {
      result.push({
        name: 'Beskrivelse',
        to: this.entry.description,
        from: this._srcEntry.description
      })
    }

    return result;
  }
}
