import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class StandardShiftsService {

  standardShifts: any[] = []

  constructor(
    private api: ApiService
  ) {
  }

  loadStandardShifts() {
    this.api.loadStandardShifts().subscribe((data: any) => {
      this.standardShifts = data.standardtitles
    })
  }
}
