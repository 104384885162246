export class deepDiffMapper {

    static VALUE_CREATED = 'created';
    static VALUE_UPDATED = 'updated';
    static VALUE_DELETED = 'deleted';
    static VALUE_UNCHANGED = 'unchanged';

    protected isFunction(obj: object) {
        return {}.toString.apply(obj) === '[object Function]';
    };

    protected isArray(obj: object) {
        return {}.toString.apply(obj) === '[object Array]';
    };

    protected isObject(obj: object) {
        return {}.toString.apply(obj) === '[object Object]';
    };

    protected isDate(obj: object) {
        return {}.toString.apply(obj) === '[object Date]';
    };

    protected isValue(obj: object) {
        return !this.isObject(obj) && !this.isArray(obj);
    };

    protected isEmpty(obj) {
        return !obj || Object.keys(obj).length === 0;
    }

    protected compareValues(value1: any, value2: any) {
        if (value1 === value2) {
            return deepDiffMapper.VALUE_UNCHANGED;
        }
        if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
            return deepDiffMapper.VALUE_UNCHANGED;
        }
        if ('undefined' == typeof (value1)) {
            return deepDiffMapper.VALUE_CREATED;
        }
        if ('undefined' == typeof (value2)) {
            return deepDiffMapper.VALUE_DELETED;
        }

        return deepDiffMapper.VALUE_UPDATED;
    }

    public map(obj1: object, obj2: object) {
        if (this.isFunction(obj1) || this.isFunction(obj2)) {
            throw 'Invalid argument. Function given, object expected.';
        }
        if (this.isValue(obj1) || this.isValue(obj2)) {
            return {
                type: this.compareValues(obj1, obj2),
                data: (obj1 === undefined) ? obj2 : obj1
            };
        }

        var diff = {};
        for (var key in obj1) {
            if (this.isFunction(obj1[key])) {
                continue;
            }

            var value2 = undefined;
            if ('undefined' != typeof (obj2[key])) {
                value2 = obj2[key];
            }

            diff[key] = this.map(obj1[key], value2);
        }
        for (var key in obj2) {
            if (this.isFunction(obj2[key]) || ('undefined' != typeof (diff[key]))) {
                continue;
            }

            diff[key] = this.map(undefined, obj2[key]);
        }

        return diff;

    }

    public mapDiffOnly(obj1: object, obj2: object) {
        let map = this.map(obj1, obj2);
        return this.diffOnly(map)
    }

    public mapDiffOnlyFlat(obj1: object, obj2: object) {
        let map = this.map(obj1, obj2)
        //console.log('map', map);

        let diff = this.diffOnly(map);
        //console.log('diff', diff);

        let result = {}
        return this.flatten(diff, result);
    }

    private flatten(diff: object, result: object) {
        //console.log('flatten', diff)
        for (const key in diff) {
            if (diff.hasOwnProperty(key) && this.isObject(diff[key]) && diff[key].path !== undefined) {
                //console.log('flatten.key', key, diff[key])
                result[diff[key].path] = diff[key].value
                if (this.isObject(diff[key].value)) {
                    result = this.flatten(diff[key].value, result)
                }
            }
        }

        return result;
    }

    private diffOnly(diff: object, path: string = "") {
        let result = {}
        for (const key in diff) {
            if (diff.hasOwnProperty(key)) {
                if (diff[key].type === undefined) {
                    let subDiff = this.diffOnly(diff[key], path + key + ".");
                    if (!this.isEmpty(subDiff)) {
                        result[key] = {
                            type: deepDiffMapper.VALUE_UPDATED,
                            path: path + key,
                            value: subDiff
                        };
                    }
                }
                else if (diff[key].type !== deepDiffMapper.VALUE_UNCHANGED) {
                    result[key] = {
                        type: diff[key].type,
                        path: path + key,
                        value: diff[key].data
                    }
                }
            }
        }

        return result
    }
}