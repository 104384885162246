import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { first, share } from 'rxjs/operators';
import { AccountService } from './account.service';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class HttpPostService {

  private loading;
  public token: string;

  constructor(private http: HttpClient, private config: ConfigService, private loadingController: LoadingController) {
  }

  async presentLoading(loadingText: string) {
    this.loading = await this.loadingController.create({
      message: loadingText
    });
    await this.loading.present();
  }

  async dismissLoading() {
    if (this.loading) this.loading.dismiss()
    this.loading = null;
  }

  public ajaxGet(url) {
    return this.http.get(url).pipe(first());
  }

  public post(api_path: string, data?, loadingText?: string) {
    if (loadingText) this.presentLoading(loadingText)

    if (!data) data = {}
    if (!data.token && this.token) data.token = this.token;
    /*console.log('http.post', this.config.api_url + api_path, data, {
      headers:
        { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
    })*/
    const result = this.http.post(this.config.api_url + api_path, data).pipe(share());
    result.subscribe(
      data => {
        this.dismissLoading()
      },
      error => {
        this.dismissLoading()
      })
    return result;
  }
}
