import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private endpoints = {
    cas_dev: 'http://cas-booking.local',
    johnny_cas_ip: 'http://192.168.0.100',
    johnny_home_ip: 'http://192.168.1.241',
    local_ip: 'http://127.0.0.1',
    // live_1: 'https://vikarbooking.cas.dk',
    staging: 'https://staging.vikarbooking.cas.dk'
  }
  private config = {
    api_url: this.endpoints.staging + '/v1/',

    this_app_version: '%%VERSION%%', //'1.6.1',
    app_vesions: [],


    onesignal_id: '70b2e375-66f9-426e-94b3-21c7602f2900',

    dateFormats: {
      postDateFormat: 'YYYY-MM-DD HH:mm:ss',
      rangeDateFormat: 'D MMM YYYY \\k\\l\\. HH:mm',
      calendarFormat: 'ddd D MMM YYYY',
      calendarFormatDayMonth: 'D/M',
      timeFormat: 'HH:mm',
      timeFormatWithSeconds: 'HH:mm:00',
      dateFormat: 'YYYY-MM-DD',
      monthDateFormat: 'YYYY-MM',
      weekNumber: 'w',
      year: "YYYY"
    }
  };


  constructor() { }

  get api_url() {
    return this.config.api_url;
  }

  get dateFormats() {
    return this.config.dateFormats;
  }

}
